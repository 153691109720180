<template>
  <div>
    <!-- 基础信息 -->
    <en-table-layout :tableData="tableData.data">
      <template slot="toolbar">
        <div class="top clearfix" style="width:100%">
          <div class="content-header">
            基础信息
          </div>
          <div class="msgleft">
            <div class="pad">
              <span class="title">用户ID：</span>
              <span>{{ userMsg.member_id ? userMsg.member_id : '' }}</span>
            </div>
            <div class="pad">
              <span class="title">用户姓名：</span>
              <span>{{ userMsg.uname }}</span>
            </div>
            <div class="pad">
              <span class="title">产生订单数：</span>
              <span>{{ userMsg.order_num ? userMsg.order_num : "0" }}</span>
              <i class="goorder" @click="goorder" v-if="userMsg.order_num !== 0">查看订单</i>
            </div>
            <div class="pad">
              <span class="title">最后登录的时间：</span>
              <span>{{ userMsg.last_login | unixToDate("yyyy-MM-dd hh:mm:ss") }}</span>
            </div>
          </div>
          <div class="msgright">
            <div class="pad">
              <span class="title">手机号：</span>
              <span>{{ userMsg.mobile ? userMsg.mobile : "" }}</span>
            </div>
            <div class="pad">
              <span class="title">注册来源：</span>
              <span>{{ userMsg.join_way | filtersJoinway }}</span>
            </div>
            <div class="pad">
              <span class="title">注册时间：</span>
              <span>{{ userMsg.create_time | unixToDate("yyyy-MM-dd hh:mm:ss") }}</span>
            </div>
          </div>
          <div class="msgtable" style="clear: both;">
            <div class="content-header">
              绑卡记录
            </div>
            <div class="col-12"
              style="display: flex; justify-content: space-between; align-items: center;margin-top:10px">
              <div class="row no-gutters align-items-center">
                <el-form-item label="绑卡时间">
                  <el-date-picker style="width: 250px" v-model="order_time_range" type="daterange" align="center"
                    size="medium" :editable="false" unlink-panels range-separator="-" start-placeholder="开始日期"
                    end-placeholder="结束日期"></el-date-picker>
                </el-form-item>
              </div>
              <div class="row no-gutters align-items-center">
                <el-form-item label="卡券类型">
                  <el-select style="width: 100px" v-model="inform.card_type" size="medium" placeholder="请选择"
                    class="choose-machine" clearable>
                    <el-option label="全部" value></el-option>
                    <el-option label="计次卡" :value="6"></el-option>
                    <el-option label="宅配卡" :value="7"></el-option>
                  </el-select>
                </el-form-item>
              </div>
              <div class="row no-gutters align-items-center">
                <el-form-item label="卡券名称">
                  <el-input size="medium" style="width: 180px" v-model.trim="inform.card_name" placeholder="请输入卡券名称"
                    clearable />
                </el-form-item>
                <el-button size="small" type="primary" @click="getUserChange()">
                  搜索
                </el-button>
              </div>
            </div>
          </div>
        </div>
      </template>
      <template slot="table-columns">
        <el-table-column prop="card_name" label="卡券名称">
          <template slot-scope="scope">{{ scope.row.card_name }}</template>
        </el-table-column>
        <el-table-column prop="card_name" label="卡券类型">
          <template slot-scope="scope">{{ scope.row.card_type == 6 ? '计次卡' : '宅配卡' }}</template>
        </el-table-column>
        <el-table-column prop="card_code" label="卡号">
          <template slot-scope="scope">{{ scope.row.has_give_card == 1 ? filterCard(scope.row.card_code) :
      scope.row.card_code }}{{ scope.row.has_give_card == 1 ? '（好友赠送）' : ''
            }}</template>
        </el-table-column>
        <el-table-column prop="left_available_times" label="剩余可用次数">
          <template slot-scope="scope">{{ scope.row.left_available_times }}/{{ scope.row.available_times
            }}</template>
        </el-table-column>
        <!-- <el-table-column prop="exchange_time" label="绑卡时间" width="160">
                <template slot-scope="scope">{{ scope.row.exchange_time | unixToDate("yyyy-MM-dd hh:mm:ss") }}</template>
              </el-table-column> -->
        <el-table-column prop="binding_time" label="绑卡时间" width="160">
          <template slot-scope="scope">{{ scope.row.binding_time | unixToDate("yyyy-MM-dd hh:mm:ss") }}</template>
        </el-table-column>
        <el-table-column label="操作" width="230px">
          <template slot-scope="scope">
            <el-button size="mini" type="danger" plain @click="handleSuspend(scope.row, 'handleSuspend')"
              v-if="scope.row.card_type == 7 && scope.row.delivery_status == 1">暂停配送</el-button>
            <el-button size="mini" type="primary" plain @click="handleSuspend(scope.row, 'handleRestore')"
              v-if="scope.row.card_type == 7 && scope.row.delivery_status == 2">恢复配送</el-button>
            <el-button size="mini" type="primary" plain @click="handleOrder(scope.$index, scope.row)">卡券兑换记录</el-button>
          </template>
        </el-table-column>
      </template>
      <el-pagination slot="pagination" @size-change="handleSizeChange" @current-change="handleCurrentChange"
        :current-page="tableData.page_no" :page-sizes="MixinPageSizes" background :layout="MixinTableLayout"
        :page-size="tableData.page_size" :total="tableData.data_total" />

    </en-table-layout>
  </div>
</template>
<script>
import * as API_Users from "@/api/users";
import * as API_member from "@/api/member";
import EnTableLayout from '../../../../ui-components/TableLayout/src/main';

export default {
  name: "timescount",
  components: { EnTableLayout },
  filters: {
    filtersJoinway (data) {
      switch (data) {
        case 1:
          return "页面注册";
        case 3:
          return "表格导入";
        default:
          return "移动端注册";
      }
    }
  },
  data () {
    return {
      inform: {
        card_type: '',
        card_name: '',
        page_no: 1,
        page_size: 20
      },
      order_time_range: '',
      userMsg: {},
      tableData: {}
    };
  },
  created () {
    this.getmemberMsg();
  },
  methods: {
    handleSuspend (row, type) {//暂停配送
      if (type == 'handleSuspend') {
        API_member.TradeJCSuspend({ card_key: row.card_key }).then(res => {
          this.$message.success('暂停成功！');
          this.getList();
        });
      } else {//恢复配送
        API_member.TradeJCStart({ card_key: row.card_key }).then(res => {
          this.$message.success('恢复成功！');
          this.getList();
        });
      }
    },
    getmemberMsg () {//查询基本信息详情
      API_member.getmembersgetInfo(this.$route.params.id).then(res => {
        this.userMsg = res;
      });
      this.getUserChange()
    },
    //搜索
    getUserChange () {
      this.inform.page_no = 1;
      this.getList();
    },
    /**请求列表数据 */
    getList () {
      let inform = { ...this.inform }
      if (this.order_time_range) {
        const exchange_start = this.order_time_range[0] / 1000
        const exchange_end = this.order_time_range[1] / 1000 + 86399
        inform['start_time'] = exchange_start
        inform['end_time'] = exchange_end
      }
      API_Users.getCardBindMemberList(this.$route.params.id, inform).then(res => {
        this.tableData = res;
      });
    },
    filterCard (card) {
      return card.split('-')[2]
    },
    //卡券兑换记录
    handleOrder (index, row) {
      this.$router.push({
        path: `/combo-card-shop/order/order-list`,
        query: {
          trade_type: row.card_type == 6 ? 3 : 4,//卡券类型
          card_code: row.card_code,//卡号
          mobile: row.binding_member_mobile//手机号
        }
      });
    },
    //查看订单
    goorder () {
      this.$router.push(`/combo-card-shop/order/order-list?member_id=${this.userMsg.member_id}`);
    },
    handleSizeChange (val) {
      this.inform.page_size = val;
      this.getList();
    },
    handleCurrentChange (val) {
      this.inform.page_no = val;
      this.getList();
    }
  }
};
</script>
<style lang="scss" scoped>
@media screen and (max-device-width: 1366px) {
  .top {
    //border: 1px solid #666;
    color: #666666;
    border-radius: 10px 10px 0 0;
    background: #fff;
  }

  .goorder {
    margin-left: 25px;
    color: #0099ff;
    font-style: normal;
    cursor: pointer;
  }

  .top .msgleft {
    float: left;
  }

  .top .msgright {
    float: left;
    margin-left: 130px;
  }

  .msgtable {
    padding-bottom: 10px;
  }

  .pad {
    .title {
      font-size: 11px;
      font-weight: bold;
      color: #333333;
    }

    padding: 5px;
  }

  .content-header {
    height: 43px;
    border-radius: 7px 7px 0 0;
    background: #E6ECF7;
    padding-left: 20px;
    line-height: 43px;
    font-size: 13px;
    font-weight: bold;
    color: #333333;
  }
}

@media screen and (min-device-width: 1440px) {
  .top {
    //border: 1px solid #666;
    color: #666666;
    border-radius: 10px 10px 0 0;
    background: #fff;
  }

  .goorder {
    margin-left: 25px;
    color: #0099ff;
    font-style: normal;
    cursor: pointer;
  }

  .top .msgleft {
    float: left;
  }

  .top .msgright {
    float: left;
    margin-left: 130px;
  }

  .msgtable {
    padding-bottom: 10px;
  }

  .pad {
    .title {
      font-size: 16px;
      font-weight: bold;
      color: #333333;
    }

    padding: 10px;
  }

  .content-header {
    height: 50px;
    border-radius: 10px 10px 0 0;
    background: #E6ECF7;
    line-height: 50px;
    padding-left: 20px;
    font-size: 18px;
    font-weight: bold;
    color: #333333;
  }
}

::v-deep .en-table-layout .en-table-header {
  padding: 0;
}

::v-deep .en-table-layout .en-table-layout-toolbar {
  padding: 0
}

.en-table-layout {
  background: transparent;
}
</style>
